import React from 'react';
import HeroBannerContainer from '../../../container/HeroBanner';
import bannerBg from '../../../assets/images/portfolio/hero.jpg';
import personalInfo from '../../../data/personalInfo';

const BannerPersonalPortfolio = () => {
	return (
		<HeroBannerContainer
			bg={bannerBg}
			overlay={false}
		>
			<div className="main-slider-content text-background">
				<h2>Hello, I’m</h2>
				<h1>{personalInfo.name}</h1>
				<h2>{personalInfo.designation}</h2>
			</div>
		</HeroBannerContainer>
	);
};

export default BannerPersonalPortfolio;
