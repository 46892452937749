import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/*
 * @ Component Imported
 * */
import AboutPage from './pages/about';
import ErrorPage from './pages/404Error';
import ContactPage from './pages/contact';
import ServicePage from './pages/service';
import PortfolioDetailsPage from './pages/portfolio/details';
import HomePersonalPortfolio from './pages/home/HomePersonalPortfolio';
import PortfolioMasonryThreeColumnFullWidthPage from './pages/portfolio/masonry/three-column-fullwidth';

const App = () => {
	return (
		<Fragment>
			<Router>
				<Switch>
					<Route
						path={`${process.env.PUBLIC_URL + '/contact'}`}
						component={ContactPage}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/service'}`}
						component={ServicePage}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/portfolio/:slug'}`}
						component={PortfolioDetailsPage}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/portfolio'}`}
						component={PortfolioMasonryThreeColumnFullWidthPage}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/about'}`}
						component={AboutPage}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/'}`}
						component={HomePersonalPortfolio}
					/>
					<Route
						exact
						component={ErrorPage}
					/>
				</Switch>
			</Router>
		</Fragment>
	);
};

export default App;
